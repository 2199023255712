// store/index.js
import { createStore } from "vuex";

export default createStore({ 
  state: {
    categories: [],
    tags: [],
    pages: [],
    frontposts: [],
    frontsingleposts: [],
    projectposts: [],
    twocolumnpost: [],
    tiledetails: "",
  },
  getters: {},
  mutations: {
    setCategories(state, categories) {
      // mutate state
      state.categories = categories;
    },
    setTags(state, tags) {
      // mutate state
      state.tags = tags;
    },

    setFrontPosts: (state, data) => {
      state.frontposts = data;
    },

    setFrontSinglePosts: (state, data) => {
      state.frontsingleposts = data;
    },

    setProjectPosts: (state, data) => {
      state.projectposts = data;
    },
    setTwoColumnPost(state, data) {
      state.twocolumnpost = data;
    },
    setPages(state, pages) {
      // mutate state
      state.pages = pages;
    },
    setDetailTilePos(state, data) {
      // mutate state
      state.tiledetails = data;
    },
  },
  actions: {},
});



