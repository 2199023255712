<template>
  <div class="three-column-page">
    <div class="container slideshow-container" style="min-height:400px">
      <div class="row" v-if="$store.state.pages.startseite">
        <div class="column" v-if="$store.state.pages.startseite.slider.length > 1">
          <swiper
              class="swiper"
              :modules="modules"
              :slides-per-view="1"
              :loop="true"
              :pagination="{ clickable: true }"
              :navigation="true"
              :space-between="30"
              :centered-slides="true"
              :autoplay="{
                delay: 5000,
                disableOnInteraction: false
              }"
            >
            <swiper-slide
              v-for="slide in $store.state.pages.startseite.slider"
              v-bind:key="slide.key"
            >
              <img :src="slide.url" />
            </swiper-slide>
          </swiper>
        </div>
        <div
          class="column single-hero-image"
          v-if="$store.state.pages.startseite.slider.length == 1"
        >
          <img :src="this.$store.state.pages.startseite.slider[0].url" />
        </div>
      </div>
    </div>
    <div class="container tile content-container" v-if="$store.state.pages.startseite" style="min-height:1400px">
      <div class="row">
        <div class="column outer-column" v-for="column in columns" v-bind:key="column.key">
          <div class="container">
            <div class="row flex-direction-column vertical-row">
              <div
                v-for="erstespalte in column.slice(0,tilesToShow)"
                v-bind:key="erstespalte.key"
                @click="erstespalte.veroeffentlicht.length == 2 ? goToTileDetailChat(erstespalte) : { click: ($event) => $event.preventDefault() }"
              >
                <div
                  class="column white-background"
                  v-bind:class="erstespalte.veroeffentlicht.length == 2 ? 'tile-animation coursor-pointer tile-box-margin' : 'tile-box-margin'"
                >
                  <div class="image-wrapper">
                    <div v-if="screenWidth < 1280">
                        <img :src="erstespalte.kachelbild.sizes.custommedium" :alt="erstespalte.kachelbild.alt"/>
                    </div>
                    <div v-else>
                      <img :src="erstespalte.kachelbild.sizes.customsmall" :alt="erstespalte.kachelbild.alt"/>
                    </div>
                  </div>
                  <div class="tile-padding tile-text-wrapper" :class="erstespalte.cssclass">
                    <span class="roboto-medium-headline red-font">{{erstespalte.uberschrift}}</span>
                    <br />
                    <span class="roboto-condensed-regular">{{erstespalte.unteruberschrift}}</span>
                    <p
                      class="roboto-condensed-regular roboto-condensed-light top-margin-medium"
                      v-html="erstespalte.teasertext"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="$store.state.frontposts">
        <div class="colum mehr-button">
          <div v-if="cp > 3 && tilesToShow < cp" @click="loadMore" class="roboto-medium">[ Mehr ]</div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>

import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'



export default {
    url: import.meta.url,
    components: {
      Swiper,
      SwiperSlide
    },
    setup() {
      return {
        modules: [Pagination, Navigation, Autoplay]
      }
    },
  name: "HomeTileOverview",

  data() {
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination"
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        loop: true,
        speed: 2000,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        spaceBetween: 150,
        centeredSlides: true
      },
      tilesToShow: 3,
      window: {
        width: 0,
        height: 0
      },
      screenWidth: 0
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    scrollToTop() {
      window.scroll(0, 0);
    },
    goToTileDetailChat(tile) {
      this.$store.commit("setDetailTilePos", tile);
      this.$router.push({ path: `start/${tile.slug}/${tile.id}` });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    loadMore() {
      this.tilesToShow *= 2;
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    }
  },
  computed: {
    cp: function() {
      // computed property adjusts the length for the load more button to keep everything consistent event the single posts array is an array of one array
      if (this.window.width < 638) {
        return this.$store.state.frontsingleposts[0].length;
      }
      if (this.window.width >= 638 && this.window.width <= 1278) {
        return this.$store.state.twocolumnpost.columns[0].length;
      }
      return this.$store.state.frontposts.columns[0].length;
    },
    columns: function() {
      // computed property adjusts the length for the load more button
      if (this.window.width < 638) {
        return this.$store.state.frontsingleposts;
      }
      if (this.window.width >= 638 && this.window.width <= 1278) {
        return this.$store.state.twocolumnpost.columns;
      }
      return this.$store.state.frontposts.columns;
    }
  }
};
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>