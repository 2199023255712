<template>
  <div id="app">
    <!--Navigation-->
    <div id="navbar" class="nav-wapper">
      <nav>
        <ul class="topmenu">
          <li class="logo">
            <router-link to="/" aria-label="Link Bureau Veral Home">
              <img src="./assets/images/Logo_Versal_vorab.svg" height="76em" />
            </router-link>
          </li>
          <li class="item">
            <router-link to="/" aria-label="Link Bureau Veral Startseite">Start</router-link>
          </li>
          <li class="item">
            <router-link to="/profil" aria-label="Link Bureau Veral Profil">Profil</router-link>
          </li>
          <li class="item">
            <router-link to="/leistungen" aria-label="Link Bureau Veral Leistungen">Leistungen</router-link>
          </li>
          <li class="item">
            <router-link to="/projekte" aria-label="Link Bureau Veral Projekte">Projekte</router-link>
          </li>
          <li class="item">
            <router-link to="/referenzen" aria-label="Link Bureau Veral Referenzen">Referenzen</router-link>
          </li>
          <li class="item">
            <router-link to="/kontakt" aria-label="Link Bureau Veral Kontakt">Kontakt</router-link>
          </li>
        </ul>
        <div class="mobile-menu">
          <input type="checkbox" id="hamburg" />
          <label for="hamburg" class="hamburg">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </label>

          <nav class="topmenu">
            <ul>
              <li>
                <router-link to="/" aria-label="Link Bureau Veral Startseite">Start</router-link>
              </li>
              <li>
                <router-link to="/profil" aria-label="Link Bureau Veral Profil">Profil</router-link>
              </li>
              <li>
                <router-link to="/leistungen" aria-label="Link Bureau Veral Leistungen">Leistungen</router-link>
              </li>
              <li>
                <router-link to="/projekte" aria-label="Link Bureau Veral Projekte">Projekte</router-link>
              </li>
              <li>
                <router-link to="/referenzen" aria-label="Link Bureau Veral Referenzen">Referenzen</router-link>
              </li>
              <li>
                <router-link to="/kontakt" aria-label="Link Bureau Veral Kontakt">Kontakt</router-link>
              </li>
            </ul>
          </nav>
        </div>
      </nav>
    </div>

    <div class="main-content">
      <router-view :key="$route.path"></router-view>
    </div>
    <!--Footer-->
    <div class="footer-wrapper" v-if="$store.state.pages.kontakt">
      <div class="container roboto-condensed-regular roboto-condensed-light">
        <div class="row">
          <div class="column">
            Bureau Versal
            <br />Diplom-Designerin
            <br />Ann Katrin Siedenburg
            <br />
          </div>
          <div class="column enable-newline">
            {{ this.$store.state.pages.footer.zweite_spalte.ort }}
            <br />
            {{ this.$store.state.pages.footer.zweite_spalte.strasse }}
            <br />
            {{ this.$store.state.pages.footer.zweite_spalte.postleitzahl }}
            {{ this.$store.state.pages.footer.zweite_spalte.stadt }}
          </div>
          <div class="column">
            <a>{{ this.$store.state.pages.footer.dritte_spalte.telefon }}</a>
            <br />
            <a
              :href="
                `mailto:${this.$store.state.pages.footer.dritte_spalte.email}`
              "
            >{{ this.$store.state.pages.footer.dritte_spalte.email }}</a>
          </div>
          <div class="column">
            <router-link to="/datenschutz" aria-label="Link Bureau Veral Impressum">[ Impressum ]</router-link>
            <br />
            <router-link to="/datenschutz" aria-label="Link Bureau Veral Datenschutz">[ Datenschutz ]</router-link>
          </div>
          <div class="column">
            <button aria-label="Back to top Button" class="back-to-top-button" @click="scrollToTop(400)"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useHead } from '@vueuse/head'
export default {
  name: "app",
  setup() {
    useHead({
      // Can be static or computed
      title: 'Bureau Versal | Grafikdesign aus Berlin',
      meta: [
        {
          name: `description`,
          content: 'Konzeption und Gestaltung für Print + Web: Editorial Design, Corporate Design, Design Corporate Publishing, Buchgestaltung, Webdesign',
        },
        ],
    
    })
  },
  data() {
    return {
      //If you want to change the positions of the permanent posts, chang them here
      prestigeposts: [2, 3, 8, 11, 12, 17, 20, 21, 26],
      pages: {},
      categories: [],
      tags: [],
      isVisible: false,
      baseUrl: "https://backend.bureau-versal.de",
      posts: []
    };
  },
  async created() {
    this.posts = await this.getCustomFieldPosts().then(res =>
      res.map(x => {
        let container = {};

        container = x.acf;
        container["id"] = x.ID;

        return container;
      })
    );
    await this.getRegularFieldPosts().then(res => {
      for (let post in res) {
        this.posts[post].categories = res[post].categories;
        this.posts[post].tags = res[post].tags;
        this.posts[post].slug = res[post].slug;
      }
    });
    this.categories = await this.getCategories().then(res =>
      res.map(x => {
        const container = {};

        container["slug"] = x.slug;
        container["name"] = x.name;
        container["id"] = x.id;
        container["count"] = x.count;

        return container;
      })
    );

    this.tags = await this.getTags().then(res =>
      res.map(x => {
        const container = {};

        container["slug"] = x.slug;
        container["name"] = x.name;
        container["id"] = x.id;
        container["count"] = x.count;

        return container;
      })
    );
    this.pages = await this.getPages().then(res =>
      res.reduce((result, item) => {
        result[item.post_name] = item.acf;
        return result;
      }, {})
    );
    this.$store.commit("setPages", this.pages);

    this.addRelatedPostsToCategories(),
      this.$store.commit("setCategories", this.categories);

    this.addRelatedTagsToCategories();
    this.$store.commit("setTags", this.tags);

    this.resortTiles();
  },
  methods: {
    async getCustomFieldPosts() {
      const response = await fetch(
        `${this.baseUrl}/wp-json/bureauversal/v1/post/`
      );
      let data = await response.json();
      return data;
    },
    async getRegularFieldPosts() {
      const response = await fetch(
        `${this.baseUrl}/wp-json/wp/v2/posts?per_page=100`
      );
      let data = await response.json();
      return data;
    },
    async getCategories() {
      const response = await fetch(
        `${this.baseUrl}/wp-json/wp/v2/categories`
      );
      let data = await response.json();
      return data;
    },
    async getTags() {
      const response = await fetch(
        `${this.baseUrl}/wp-json/wp/v2/tags?per_page=100`
      );
      let data = await response.json();
      return data;
    },
    async getPages() {
      const response = await fetch(
        `${this.baseUrl}/wp-json/bureauversal/v1/pages/`
      );
      let data = await response.json();
      return data;
    },

    scrollToTop(scrollDuration) {
      let cosParameter = window.scrollY / 2,
        scrollCount = 0,
        oldTimestamp = performance.now();
      function step(newTimestamp) {
        scrollCount +=
          Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
        if (scrollCount >= Math.PI) window.scrollTo(0, 0);
        if (window.scrollY === 0) return;
        window.scrollTo(
          0,
          Math.round(cosParameter + cosParameter * Math.cos(scrollCount))
        );
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
      }
      window.requestAnimationFrame(step);
    },
    addRelatedPostsToCategories() {
      let category;
      for (category in this.categories) {
        let relatedPosts = [];
        for (let post of this.posts) {
          if (post.categories.includes(this.categories[category].id)) {
            relatedPosts.push(post.id);
          }
        }
        this.categories[category].relatedPosts = relatedPosts;
      }
    },
    addRelatedTagsToCategories() {
      let tag;
      for (tag in this.tags) {
        let relatedTags = [];
        for (let post of this.posts) {
          if (post.tags.includes(this.tags[tag].id)) {
            relatedTags.push(post.id);
          }
        }
        this.tags[tag].relatedTags = relatedTags;
      }
    },

    //The newest Posts will be shown first, some posts are meant to be shown regardless when they are posted at specific positions.
    //Therefore the posts have to be resorted.
    resortTiles() {
      let tempObject = {
        Erste_Seite: [],
        Zweite_Seite: [],
        Dritte_Seite: []
      };
      let removeIndex = [];
      let projectIndex = [];
      let tempArray = this.posts;
      //Objects and arrays to store the tiles
      let threeColumnObject = {
        columns: [[], [], []],
        column_counter: [0, 0, 0]
      };
      let twoColumnObject = {
        columns: [[], []]
      };
      let frontArray = [[]];
      let projectArray = [];

      //Check which of the posts are to be on the first, second and third Page permanently and store them in an object
      for (let post in tempArray) {
        for (let category in tempArray[post].categories) {
          let result = this.categories.find(
            temp => temp.id == tempArray[post].categories[category]
          );

          tempArray[post].categories[category] = result.slug;
        }
        tempArray[post].teasertext.length === 0
          ? ((tempArray[post].cssclass = "tile-headline-teaser"),
            (tempArray[post].totalheight = 3))
          : ((tempArray[post].cssclass = "tile-full-text"),
            (tempArray[post].totalheight = 7));

        tempArray[post].fototyp_kachelbild.fototyp == "standard"
          ? (tempArray[post].totalheight = tempArray[post].totalheight + 7)
          : (tempArray[post].totalheight = tempArray[post].totalheight + 11);

        if (tempArray[post].prestige_kachel != "Keine") {
          let pageIdentifier = tempArray[post].prestige_kachel;
          tempObject[pageIdentifier].push(tempArray[post]);
          removeIndex.push(post);
        }
      }
      //When the posts are found remove them from the array
      while (removeIndex.length) {
        tempArray.splice(removeIndex.pop(), 1);
      }
      //Put the posts back in their intended positions
      for (let postToPut in tempObject.Erste_Seite) {
        tempArray.splice(
          this.prestigeposts[postToPut],
          0,
          tempObject.Erste_Seite[postToPut]
        );
      }
      for (let postToPut in tempObject.Zweite_Seite) {
        let tempIndex = Number(postToPut) + 3;
        tempArray.splice(
          this.prestigeposts[tempIndex],
          0,
          tempObject.Zweite_Seite[postToPut]
        );
      }
      for (let postToPut in tempObject.Dritte_Seite) {
        let tempIndex = Number(postToPut) + 6;
        tempArray.splice(
          this.prestigeposts[tempIndex],
          0,
          tempObject.Dritte_Seite[postToPut]
        );
      }
      //prepare home and project page tiles
      for (let project in tempArray) {
        if (tempArray[project].veroeffentlicht.length == 2) {
          projectArray.push(tempArray[project]);
          frontArray[0].push(tempArray[project]);
        }
        if (
          tempArray[project].veroeffentlicht.length == 1 &&
          tempArray[project].veroeffentlicht.includes("projekte")
        ) {
          projectArray.push(tempArray[project]);
          projectIndex.push(project);
        }
        if (
          tempArray[project].veroeffentlicht.length == 1 &&
          tempArray[project].veroeffentlicht.includes("startseite")
        )
          frontArray[0].push(tempArray[project]);
      }

      while (projectIndex.length) {
        tempArray.splice(projectIndex.pop(), 1);
      }
      //Three Column Project Layout
      for (let i = 0; i < tempArray.length; i++) {
        if (i % 3 == 0) {
          threeColumnObject.columns[0].push(tempArray[i]);
          threeColumnObject.column_counter[0] =
            threeColumnObject.column_counter[0] + tempArray[i].totalheight;
        }
        if (i % 3 == 1) {
          threeColumnObject.columns[1].push(tempArray[i]);
          threeColumnObject.column_counter[1] =
            threeColumnObject.column_counter[1] + tempArray[i].totalheight;
        }
        if (i % 3 == 2) {
          threeColumnObject.columns[2].push(tempArray[i]);
          threeColumnObject.column_counter[2] =
            threeColumnObject.column_counter[2] + tempArray[i].totalheight;
        }
        if (i % 3 == 2 && i > 2) {
          //Get the Tile with the largest height and switch it with the Tile with the smallest height

          let highestColumn = threeColumnObject.column_counter.indexOf(
            Math.max(...threeColumnObject.column_counter)
          );
          let highestEntryRange = threeColumnObject.columns[highestColumn];
          let highestArrayId = highestEntryRange
            .map(obj => {
              return obj.id;
            })
            .reduce((a, b) => (a.totalheight > b.totalheight ? a : b));

          let highestIndex = threeColumnObject.columns[highestColumn].findIndex(
            item => item.id === highestArrayId
          );
          //Get the Tile with the lowest height and switch it with the Tile with the largest height
          let lowestColumn = threeColumnObject.column_counter.indexOf(
            Math.min(...threeColumnObject.column_counter)
          );
          let lowestEntryRange = threeColumnObject.columns[lowestColumn];
          let lowestArrayId = lowestEntryRange
            .map(obj => {
              return obj.id;
            })
            .reduce((a, b) => (a.totalheight > b.totalheight ? a : b));
          let lowestIndex = threeColumnObject.columns[lowestColumn].findIndex(
            item => item.id === lowestArrayId
          );
          //change the largest with the smallest tile
          threeColumnObject.column_counter[lowestColumn] +
            threeColumnObject.columns[highestColumn][highestIndex] -
            threeColumnObject.columns[lowestColumn][lowestIndex];
          threeColumnObject.column_counter[highestColumn] -
            threeColumnObject.columns[highestColumn][highestIndex] +
            threeColumnObject.columns[lowestColumn][lowestIndex];
          let b = threeColumnObject.columns[highestColumn][highestIndex];
          threeColumnObject.columns[highestColumn][highestIndex] =
            threeColumnObject.columns[lowestColumn][lowestIndex];
          threeColumnObject.columns[lowestColumn][lowestIndex] = b;
        }
      }
      //Two Column Layout on Home Tiles
      let halfwayThrough = Math.ceil(frontArray[0].length / 2);
      twoColumnObject.columns[0] = frontArray[0].slice(0, halfwayThrough);
      twoColumnObject.columns[1] = frontArray[0].slice(
        halfwayThrough,
        frontArray[0].length
      );

      //Add all posts to the store
      this.$store.commit("setFrontSinglePosts", frontArray);
      this.$store.commit("setProjectPosts", projectArray);
      this.$store.commit("setTwoColumnPost", twoColumnObject);
      this.$store.commit("setFrontPosts", threeColumnObject);
    }
  }
};
</script>
