import { createWebHistory, createRouter } from "vue-router";
import Home from "../components/HomeTileOverview.vue";


//Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
  { path: "/", name: "HomeTileOverview", component: Home },
  {
    path: "/profil",
    name: "ProfilContent",
    component: () => import("../components/Profil"),
  },
  {
    path: "/leistungen",
    name: "LeistungenContent",
    component: () => import("../components/Leistungen"),
  },
  {
    path: "/projekte",
    name: "ProjekteContent",
    component: () => import("../components/Projekte"),
  },
  {
    path: "/referenzen",
    name: "ReferenzenContent",
    component: () => import("../components/Referenzen"),
  },
  {
    path: "/kontakt",
    name: "KontaktContent",
    component: () => import("../components/Kontakt"),
  },
  {
    path: "/datenschutz",
    name: "DatenschutzContent",
    component: () => import("../components/Datenschutz"),
  },
  {
    path: "/:location/:slug/:id",
    name: "HomeTileDetail",
    component: () => import("../components/HomeTileDetail.vue"),
  },
];
//Example
//{
//path: '/about',
//name: 'About',
// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
//component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//}

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ block: "start" });
  },
});

export default router;
