import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store'
import { createHead } from '@vueuse/head'


import "normalize.css/normalize.css";
import "milligram/dist/milligram.css";
require("./assets/css/custom.css");

import "swiper/swiper-bundle.min.css";
import VueAwesomeSwiper from "vue-awesome-swiper";




const app = createApp(App)
const head = createHead()

app.use(router);
app.use(store);
app.use(VueAwesomeSwiper);
app.use(head);

app.mount("#app");

//Sticky Navigation

// When the user scrolls the page, execute scrollFunction
window.onscroll = function() {
  scrollFunction();
};

// Get the navbar
const navbar = document.getElementById("navbar");

// Get the offset position of the navbar
const sticky = navbar.offsetTop;

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function scrollFunction() {
  if (window.pageYOffset > sticky) {
    navbar.classList.add("sticky", "nav-bottom-line");
  } else {
    navbar.classList.remove("sticky", "nav-bottom-line");
  }
}


export default {
  setup(){
    let products = store.state.products;
    return {
      products
    }
  }
}